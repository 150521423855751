import * as React from "react";
import { Link } from "gatsby";
import Aside from "../components/Aside";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const headingStyles = {
  marginTop: 0,
  // marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  const { settings } = useSiteMetadata();
  console.log(settings);
  return (
    <Aside autoPop={true}>
      <article className='bg-white ui-border-o p-md'>
        <title>Not found</title>
        <h1 style={headingStyles} className='mb-sm md:mb-xs text-lg'>
          Page not found
        </h1>
        <p style={paragraphStyles}>
          {settings.message404}
          <br />
          <Link to='/'>Go home</Link>.
        </p>
      </article>
    </Aside>
  );
};

export default NotFoundPage;
