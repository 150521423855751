import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { navigate } from "gatsby";
import styled from "styled-components";
import PubSub from "pubsub-js";
import Plus from "../images/plus.inline.svg";

const Container = styled.aside`
  @media all and (max-width: 1080px) {
    bottom: 0;
    transform: translateY(100%);
  }
  @media all and (min-width: 1080px) {
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
`;

const Aside = ({ children, autoPop }) => {
  const [open, setOpen] = useState(false);
  // console.log(open);
  const scroller = useRef();
  useEffect(() => {
    _handleScrollBar();

    const tokenClose = PubSub.subscribe("OVERLAY_CLOSE", () => {
      // setOpen(false);
      _close();
      // setInfosOpen(false);
    });

    return () => PubSub.unsubscribe(tokenClose);
  }, []);

  const _handleScrollBar = () => {
    const scrollBarVisible =
      scroller.current.querySelector("article").getBoundingClientRect().width <
      312;

    if (scrollBarVisible)
      document.documentElement.classList.add("has-scrollbar-visible");
    else document.documentElement.classList.remove("has-scrollbar-visible");
  };

  useEffect(() => {
    if (!autoPop) return;
    setOpen(true);

    const tokenClose = PubSub.subscribe("POI_CLOSE", _close);
    const tokenInfos = PubSub.subscribe("INFOS_STATUS", (e, infoIsOpen) => {
      // console.log(e);
      if (infoIsOpen) setOpen(false);
    });

    return () => {
      PubSub.unsubscribe(tokenClose);
      PubSub.unsubscribe(tokenInfos);
    };
    // setReady(true);
  }, [autoPop]);

  useEffect(() => {
    setOpen(autoPop);
  }, [autoPop]);

  useEffect(() => {
    if (open) document.documentElement.classList.add("aside-open");
    else document.documentElement.classList.remove("aside-open");

    PubSub.publish("POI_STATUS", open);
  }, [open]);

  const _close = () => {
    // console.log("close");
    setOpen(false);
    setTimeout(() => {
      navigate("/");
    }, 150);
  };

  return (
    <Container
      className={clsx(
        "fixed md:h-screen- w-full- md:w-[360px]- lg:w-[440px]-  z-20 transition-transform duration-500 ease-in pointer-events-auto"
      )}
      style={{
        transform: open ? "translate(0,0)" : "",
      }}>
      <div className='inner overflow-y-auto  max-h-screen' ref={scroller}>
        <div className=' p-sm pb-0 md:p-lg'>{children}</div>
        <div className='close-wrapper absolute right-md top-md md:right-lg md:top-lg z-10 p-sm'>
          <button className='close' onClick={() => _close()} aria-label='close'>
            <span className='icon icon-o close'>
              <Plus />
            </span>
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Aside;
